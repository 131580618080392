// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("bootstrap")

global.toastr = require("toastr")
import "../stylesheets/application"

import dt from "datatables.net/js/jquery.dataTables.js"
import dtbs4 from "datatables.net-bs4/js/dataTables.bootstrap4.js";
import dtbuttons from "datatables.net-buttons/js/dataTables.buttons.js";
import dtbuttonsbs4 from "datatables.net-buttons-bs4/js/buttons.bootstrap4.js";
import dtbuttons_html5 from "datatables.net-buttons/js/buttons.html5.js"
import dtselect from "datatables.net-select/js/dataTables.select.js";
import dtselectbs4 from "datatables.net-select-bs4/js/select.bootstrap4.js";
import dtsearchpanes from "datatables.net-searchpanes/js/dataTables.searchPanes.js";
import dtsearchpanesbs4 from "datatables.net-searchpanes-bs4/js/searchPanes.bootstrap4.js";

import "chartkick/chart.js";

document.addEventListener("turbolinks:load", () => {
    dt(window, $);
    dtbs4(window, $);
    dtbuttons(window, $);
    dtbuttonsbs4(window, $);
    dtbuttons_html5(window, $);
    dtselect(window, $);
    dtselectbs4(window, $);
    dtsearchpanes(window, $);
    dtsearchpanesbs4(window, $);
});

document.addEventListener("turbolinks:load", function() {
  $(function () {
      $('[data-toggle="tooltip"]').tooltip()
      $('[data-toggle="popover"]').popover()
  })
})

Rails.start()
Turbolinks.start()
ActiveStorage.start()
